import { render, staticRenderFns } from "./previewSobreDrag.vue?vue&type=template&id=64ce7707"
import script from "./previewSobreDrag.vue?vue&type=script&lang=js"
export * from "./previewSobreDrag.vue?vue&type=script&lang=js"
import style0 from "./previewSobreDrag.vue?vue&type=style&index=0&id=64ce7707&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webp_xv7dyz3aa6fiwyik7znrcuigwm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports